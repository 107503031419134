// import external dependencies
import 'jquery';
import 'jquery-mask-plugin';
import '@fancyapps/fancybox/dist/jquery.fancybox.js';
import 'owl.carousel';

// Font Awesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope} from '@fortawesome/free-regular-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight} from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';
//import { faMinus } from '@fortawesome/free-solid-svg-icons';
//import { faPlus } from '@fortawesome/free-solid-svg-icons';
//import { faTrashAlt} from '@fortawesome/free-regular-svg-icons';

// We are only using the user-astronaut icon
library.add(faEnvelope, faGlobeAmericas, faSearch, faCalendarAlt, faFlag, faComments, faCircle,
  faFacebookF, faTwitter, faWhatsapp, faLinkedinIn, faInstagram, 
  faHeart, faCheck, faArrowAltCircleRight, faPlusCircle, faPlusSquare);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
