import quicklink from 'quicklink/dist/quicklink.umd';
import lozad from 'lozad';
import WOW from 'wow.js';

export default {
  init() {
    // JavaScript to be fired on all pages

    /***************
     * Lazy loading 
     ***************/
    const observer = lozad('.lozad', {
      rootMargin: '300px 300px',
      threshold: 0.1,
    });
    observer.observe();
    
    /**************
     * Wow General 
     **************/
    let wow = new WOW({
      boxClass: 'wow', 
      animateClass: 'animated', 
      offset: 0, 
      mobile: true, 
      live: true,
    });
    wow.init();

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    
    /*****************
     * MENU HAMBURGER
     *****************/
    $('.hamburger').on('click', function () {
      $(this).toggleClass('is-active');
      $('body').toggleClass('no-scroll');
      $('.menu-mobile__wrapper').toggleClass('zoomOut zoomIn is-active');
      $('.menu-mobile__content, .menu-mobile__footer, .menu-mobile__social-nav-box').delay(500).toggleClass('animated fadeInRight');
      
      if(!$('.header').data('sticky')) {
        $('.header').toggleClass('--sticky --swap');
      }

      if ($(this).attr('aria-expanded') === 'true') {
        $(this).attr('aria-expanded', 'false');
      } else {
        $(this).attr('aria-expanded', 'true');
      }
    });

    /**
     * Quicklink
     */
    quicklink.listen();

    /**
     * Header Initial 
     */
    //sticky / swap / shadow
    if ($(window).scrollTop() > ($('.hero').outerHeight(true) - $('.header').height())) {
      $('.header').addClass('--sticky --swap').data('sticky', true);
    } else {
      $('.header').removeClass('--sticky --swap').data('sticky', false);
    }

    /****************
     * Window Scroll 
     ****************/
     $(window).on('scroll', function() {

      /**
      * Header
      */
      var scrollTop = $(this).scrollTop();
      var scrollHeight = ($('.hero').outerHeight(true) - $('.header').height());
      if(!$('.header').data('sticky-look')) {
        $('.header').toggleClass('--sticky --swap', scrollTop > scrollHeight).data('sticky', scrollTop > scrollHeight);
      }
    });
    
    /**
     * Hero Slider      
    */
      $('.hero__slider').owlCarousel({
      items: 1,
      nav:false,
      dots:false,
      autoplay: true,
      loop:true,
      mouseDrag:false,
      touchDrag:false,
      animateOut: 'fadeOut',
      onInitialize: function() {
        console.log('Initialize: hero__slider');
      },
      onInitialized: function() {
        console.log('Initialized: hero__slider');
      },
    });

    /**
     * Partner Slider      
     */
    $('.frontpage-partners__slider').owlCarousel({
      nav:false,
      dots:false,
      loop:true,
      autoplay: true,
      lazyLoad:true,
      lazyLoadEager:1,
      margin:10,
      responsiveClass:true,
      responsive:{
        0:{
          items:2,
        },
        600:{
          items:4,
        },
        1000:{
          items:6,
          autoplay: false,
        },
      },
      onInitialize: function() {
        console.log('Initialize: frontpage-partners__slider');
      },
      onInitialized: function() {
        console.log('Initialized: frontpage-partners__slider');
      },
    });

    /**
     * Clients Slider      
     */
    $('.frontpage-clients__slider').owlCarousel({
      nav:false,
      dots:false,
      loop:true,
      autoplay: true,
      lazyLoad:true,
      lazyLoadEager:1,
      margin:10,
      responsiveClass:true,
      responsive:{
        0:{
          items:2,
        },
        600:{
          items:4,
        },
        1000:{
          items:6,
        },
      },
      onInitialize: function() {
        console.log('Initialize: frontpage-clients__slider');
      },
      onInitialized: function() {
        console.log('Initialized: frontpage-clients__slider');
      },
    });
      
    /*****************
     * SCROLL LINK
     *****************/
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();
     
      if ($('.hamburger').hasClass('is-active')) {
        $('.hamburger').toggleClass('is-active');
        $('.menu-mobile__wrapper').toggleClass('zoomOut zoomIn is-active');
        $('.menu-mobile__content, .menu-mobile__footer, .menu-mobile__social-nav-box').delay(500).toggleClass('animated fadeInRight');
        $('body').toggleClass('no-scroll');
      }

      $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top - 75, //80 header - 5
      }, 500, 'linear');
      return false;
    });

    /**
     * Fix menu-item active
     */
    if ($('body').hasClass('blog')) {
      $('.menu-inicio').removeClass('active');
    }

    /****
     * Remove Hash
     */

    /*
    function removeLocationHash() {
      var noHashURL = window.location.href.replace(/#.*$/, '');
      window.history.replaceState('', document.title, noHashURL)
    }
    window.addEventListener('popstate', function () {
      removeLocationHash();
    });
    window.addEventListener('hashchange', function (event) {
      event.preventDefault();
      removeLocationHash();
    });
    window.addEventListener('load', function () {
      removeLocationHash();
    });
    */

    /***********
     * FORMS
     ***********/
    //Form Contato submit
    $('.form__btn').on('click', function (e) {
      e.preventDefault();
      $('#form-contato').trigger('submit');
      return false;
    });

    $(document).on('submit', '#form-contato', function() {
      return false;
    });

    // JQuery mask
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      },
    };

    $('.sp_celphones').mask(SPMaskBehavior, spOptions);

    /***********
     * FANCYBOX
     ***********/
    $('[data-fancybox="gallery_images"]').fancybox({
      // Options will go here:
      protected: true,
      loop: true,
      animationEffect: 'fade',
      hash: false,
      lang: 'pt_br',
      i18n: {
        pt_br: {
            CLOSE: 'Fechar',
            NEXT: 'Próxima',
            PREV: 'Anterior',
            ERROR: 'O conteúdo solicitado não pode ser carregado. <br/> Por favor, tente novamente mais tarde.',
            PLAY_START: 'ComeÃ§ar apresentação de slides',
            PLAY_STOP: 'Pausar apresentação de slides',
            FULL_SCREEN: 'Tela Cheia',
            THUMBS: 'Miniaturas',
            DOWNLOAD: 'Download',
            SHARE: 'Compartilhar',
            ZOOM: 'Zoom',
        },
      },
    });
  },
};
